import React from 'react'
import Icon1 from '../../images/book.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/online-course.svg'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServiceElements'

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Visi & Misi</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Kursus Berkualitas</ServicesH2>
          <ServicesP>Menjadikan setiap lembaga kursus yang bergabung di HIPKI Bone dapat meningkatkan kualitasnya untuk dapat diperkenalkan di seluruh indonesia.</ServicesP>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon2}/>
          <ServicesH2>Universal</ServicesH2>
          <ServicesP>Lembaga kursus yang saat ini hanya diakses di lokal saja, maka dengan bergabung HIPKI Bone akan terus disupport agar dapat diakses lebih luas.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Go Digital</ServicesH2>
          <ServicesP>Kursus yang dapat memudahkan mereka dan bisa diakses dari mana saja dan kapan saja sebagai layanan yang efisien.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
