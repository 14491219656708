export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'Apa itu HIPKI Bone?',
  headline: 'Wadah lembaga kursus di kabupaten Bone',
  description: 'Menjadikan setiap lembaga kursus yang bergabung di HIPKI Bone dapat meningkatkan kualitasnya untuk dapat diperkenalkan di seluruh indonesia dan manca negara.',
  buttonLabel: 'Daftar Sekarang',
  imgStart: false,
  img: '/images/team-work.svg',
  alt: 'car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Ketua HIPKI BONE',
  description: '"Kursus sangat beragam untuk membentuk keahlian dan kepribadian bangsa indonesia yang merupakan kegiatan pendidikan non formal, kursus tidak bisa digantikan dengan pendidikan formal dikarenakan melalui kursus diperlukan pengalaman dari para pengajar yang professional dibidangnya masing-masing. oleh karena hal tersebut kursus harus terus berkembang mengikuti perkembangan zaman, apalagi saat ini sudah masuk era industri 4.0 dengan beragam kemudahaan yang ditawarkan."',
  buttonLabel: 'Andi Abd Rahman, S.Sos, M.Si.',
  imgStart: true,
  img: '/images/ketua HIPKI Bone.png',
  alt: 'Ketua HIPKI BONE',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Kerjasama LKP. Adzkiya',
  headline: 'Satu-satunya Terakreditasi A di Kabupaten Bone',
  description: 'Lembaga Kursus dengan Metode Trik Unik, Mudah, 99% Praktik dan Tercepat. Bergerak di bidang Pelatihan IT dan Bimbingan CPNS, TNI/Polri dan Bimbingan masuk Sekolah Ikatan Dinas.',
  buttonLabel: 'Selengkapnya',
  imgStart: false,
  img: '/images/svg-3.svg',
  alt: 'LKP Adzkiya',
  primary: false,
  darkText: true
};
